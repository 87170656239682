<script setup lang="ts">
import { getCartPermalink, getCheckoutPermalink, getShopPermalink } from '~/lib'

const { cartItems, totalPrice, isEmpty, shippingTotal } = useCart()
const { getFormattedPrice } = usePrice()
const localePath = useLocalePath()

const { modalRef, closeButtonRef, isOpen, close, handleKeydown } = useCartModal()

watch(() => useLoadingStore().isLoading, close)
</script>

<template>
  <Teleport to="body">
    <div
      v-if="isOpen"
      ref="modalRef"
      class="modal"
      role="dialog"
      aria-modal="true"
      aria-labelledby="cart-modal-title"
      @keydown="handleKeydown"
    >
      <button ref="closeButtonRef" class="close" type="button" @click="close">
        <Icon name="large-close" />
      </button>
      <div class="title-wrapper">
        <h2 id="cart-modal-title">{{ $t('shop.sideCart.title') }}</h2>
        <span class="text-small">{{ cartItems.length }} {{ $t('shop.sideCart.products') }}</span>
      </div>
      <ShopNotifications />
      <ul v-if="!isEmpty" class="cul">
        <li v-for="cartItem in cartItems" :key="cartItem.id">
          <ShopSideCartItem :cart-item="cartItem" />
        </li>
      </ul>
      <div v-else>
        <p>{{ $t('shop.sideCart.emptyCartMessage') }}</p>
        <Button primary slide :to="localePath(getShopPermalink())" @click="close">{{
          $t('shop.sideCart.toShop')
        }}</Button>
      </div>
      <div v-if="!isEmpty" class="totals">
        <div class="row">
          <div>
            <span class="text-small f-medium">{{ $t('shop.sideCart.subtotal') }}</span>
          </div>
          <div class="price">
            <span class="text-small f-medium">{{ getFormattedPrice(totalPrice) }}</span>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="text-small f-medium">{{ $t('shop.sideCart.shipping') }}</span>
            <br />
            <span class="text-xsmall description">{{ $t('shop.sideCart.priceDescription') }}</span>
          </div>
          <div class="price">
            <span class="text-small f-medium">{{ getFormattedPrice(shippingTotal) }}</span>
          </div>
        </div>
      </div>
      <!-- <ShopPromotionCode /> -->
      <div v-if="!isEmpty" class="buttons">
        <TextLink :to="localePath(getCartPermalink())">{{ $t('shop.sideCart.toCart') }}</TextLink>
        <Button primary slide :to="localePath(getCheckoutPermalink())">{{ $t('shop.sideCart.toCheckout') }}</Button>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  width: min(100%, 612px);
  padding: 40px;
  @include breakpoint(ts) {
    padding: 120px 95px;
  }
  height: 100%;
  background-color: var(--c-white);
  box-shadow: var(--shadow);
  overflow-y: scroll;
  z-index: 102;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  @include breakpoint(ts) {
    top: 40px;
    right: 40px;
  }
  svg {
    width: 25px;
    height: 25px;
    @include breakpoint(ts) {
      width: 30px;
      height: 30px;
    }
  }
}
.title-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--p-mb);
  align-items: baseline;
  h2 {
    margin-bottom: 0;
  }
  span {
    text-align: right;
    color: var(--c-gray-75);
  }
}
.totals {
  margin-bottom: 24px;
  .row {
    border-top: 1px solid var(--c-line-light);
    padding: 24px 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
    .price {
      text-align: right;
    }
    .description {
      color: var(--c-primary);
    }
  }
}
.buttons {
  display: grid;
  gap: 24px;
  @include breakpoint(ml) {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 32px;
  }
}
</style>
